.tone-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(255, 255, 255); 
    
    /* backdrop-filter: blur(30px); */

    z-index: 5000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
 }
 
 .tone-loader img {
  
       height:30%;
       width:23%;
       background: rgba(107, 106, 106, 0.01);
       /* border-radius: 50%; */
 
 }
 
 
 @media (max-width:1200px) {
     
     .tone-loader img {
  
  height:20%;
  width:23%;
 
 }
 
 }
 
 @media (max-width:768px) {
    
     .tone-loader img {
  
  height:14%;
  width:22%;
  background: rgba(0, 0, 0, 0);
 
 }
 .tone-loader {
      box-shadow: none;
 }
 }
 
 @media (max-width:400px) {
    
    .tone-loader img {
 
 height:10%;
 width:24%;
 background: rgba(0, 0, 0, 0);
 
 }
 }