@import url('https://fonts.googleapis.com/css2?family=Ramabhadra&display=swap');
body {

    font-family: 'Ramabhadra', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}





* {
  box-sizing:border-box;
  margin:0;
  padding:0;
}



.App {
  height:100vh;
  width:100vw;
  overflow-x:hidden;
}

@media (forced-colors: active) {
  body {
      background-color: Window;
      color: WindowText;
  }

  button {
      background-color: Highlight;
      color: HighlightText;
  }

  
}